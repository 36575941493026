var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCol',{attrs:{"md":"6"}},[_c('CForm',{on:{"submit":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.isUserCreate ? _vm.$t("Create") : _vm.$t("Edit"))+" "+_vm._s(_vm.$t("User"))+" ")]),_c('CCardBody',[_c('CInput',{attrs:{"label":_vm.$t('Email'),"placeholder":_vm.$t('Enter email'),"type":"email","horizontal":"","autocomplete":"email","disabled":!_vm.isEdit,"required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('CCol',{staticClass:"mt-2",attrs:{"sm":"9"}},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Role")))]),_c('multiselect',{staticClass:"multiselect",attrs:{"clear-on-select":false,"close-on-select":false,"taggable":false,"multiple":false,"value":_vm.user.roles,"options":_vm.roles,"openDirection":"below","deselectLabel":"","selectLabel":"","selectedLabel":"","disabled":!_vm.isEdit},on:{"select":(item) => {
                if (!_vm.user.roles) {
                  _vm.user.roles = [];
                }
                _vm.user.roles.push(item);
                _vm.$forceUpdate();
              },"remove":(item) => {
                _vm.user.roles = _vm.user.roles.filter((el) => el !== item);
                _vm.$forceUpdate();
              }},scopedSlots:_vm._u([{key:"option",fn:function(scope){return [_c('div',{staticClass:"custom-control custom-checkbox",attrs:{"role":"group"}},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.user.roles && _vm.user.roles.includes(scope.option)}}),_c('label',{staticClass:"custom-control-label"},[_vm._v(" "+_vm._s(scope.option)+" ")])])]}}])})],1),(!_vm.isUserCreate)?_c('div',{staticClass:"my-2 ml-3"},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Active")))]),(_vm.isEdit)?_c('CInputRadioGroup',{attrs:{"options":_vm.YES_NO_OPTIONS,"checked":_vm.user.is_active},on:{"update:checked":function($event){return _vm.$set(_vm.user, "is_active", $event)}}}):_c('div',[_vm._v(" "+_vm._s(_vm.user.is_active ? _vm.$t("Yes") : _vm.$t("No"))+" ")])],1):_vm._e()],1),_c('CCardFooter',{attrs:{"align":"right"}},[(_vm.isEdit)?_c('div',{staticClass:"d-flex gap-1"},[_c('CButton',{attrs:{"color":"danger","size":"sm"},on:{"click":_vm.resetForm}},[_c('i',{staticClass:"fa fa-ban"}),_vm._v(" "+_vm._s(_vm.$t("Reset"))+" ")]),_c('CButton',{attrs:{"type":"submit","color":"primary","size":"sm"}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" "+_vm._s(_vm.$t("Submit"))+" ")])],1):_c('CButton',{attrs:{"color":"primary","size":"sm"},on:{"click":_vm.toggleEdit}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" "+_vm._s(_vm.$t("Edit"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }