<template>
  <CCol md="6">
    <CForm @submit.prevent="saveUser">
      <CCard>
        <CCardHeader>
          {{ isUserCreate ? $t("Create") : $t("Edit") }} {{ $t("User") }}
        </CCardHeader>
        <CCardBody>
          <CInput
            :label="$t('Email')"
            :placeholder="$t('Enter email')"
            type="email"
            horizontal
            autocomplete="email"
            :disabled="!isEdit"
            v-model="user.email"
            required
          />
          <!-- <CInput
              :label="$t('Password')"
              :placeholder="$t('Enter password')"
              type="password"
              horizontal
              :disabled="!isEdit"
              v-model="user.password"
              required
              minLength="6"
              v-if="isUserCreate"
              autocomplete="new-password"
            /> -->
          <!-- <CInput
              :label="$t('First name')"
              :placeholder="$t('Enter first name')"
              horizontal
              autocomplete="name"
              :disabled="!isEdit"
              v-model="user.firstname"
            />
            <CInput
              :label="$t('Last name')"
              :placeholder="$t('Enter last name')"
              horizontal
              autocomplete="name"
              :disabled="!isEdit"
              v-model="user.lastname"
            /> -->
          <CCol sm="9" class="mt-2">
            <div class="mb-1">{{ $t("Role") }}</div>
            <multiselect
              class="multiselect"
              :clear-on-select="false"
              :close-on-select="false"
              :taggable="false"
              :multiple="false"
              :value="user.roles"
              @select="
                (item) => {
                  if (!user.roles) {
                    user.roles = [];
                  }
                  user.roles.push(item);
                  $forceUpdate();
                }
              "
              @remove="
                (item) => {
                  user.roles = user.roles.filter((el) => el !== item);
                  $forceUpdate();
                }
              "
              :options="roles"
              openDirection="below"
              deselectLabel=""
              selectLabel=""
              selectedLabel=""
              :disabled="!isEdit"
            >
              <template slot="option" slot-scope="scope">
                <div role="group" class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :checked="user.roles && user.roles.includes(scope.option)"
                  />
                  <label class="custom-control-label">
                    {{ scope.option }}
                  </label>
                </div>
              </template>
            </multiselect>
          </CCol>
          <div class="my-2 ml-3" v-if="!isUserCreate">
            <div class="mb-1">{{ $t("Active") }}</div>
            <CInputRadioGroup
              v-if="isEdit"
              :options="YES_NO_OPTIONS"
              :checked.sync="user.is_active"
            />
            <div v-else>
              {{ user.is_active ? $t("Yes") : $t("No") }}
            </div>
          </div>
        </CCardBody>
        <CCardFooter align="right">
          <div v-if="isEdit" class="d-flex gap-1">
            <CButton @click="resetForm" color="danger" size="sm">
              <i class="fa fa-ban" /> {{ $t("Reset") }}
            </CButton>
            <CButton type="submit" color="primary" size="sm">
              <i class="fa fa-save" /> {{ $t("Submit") }}
            </CButton>
          </div>
          <CButton @click="toggleEdit" color="primary" size="sm" v-else>
            <i class="fa fa-edit" /> {{ $t("Edit") }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CForm>
  </CCol>
</template>

<script>
import { users } from "@/services/users";
import { YES_NO_OPTIONS } from "@/constants/options";

export default {
  name: "user",
  data() {
    return {
      YES_NO_OPTIONS,
      isEdit: true,
      isUserCreate: false,
      id: this.$route.params.id,
      roles: [],
      user: {},
      userCopy: {},
    };
  },
  async mounted() {
    this.isUserCreate = this.id === "new";
    if (this.isUserCreate) {
      this.isEdit = true;
      this.user.roles = ["user"];
    } else {
      this.fetchUser();
      this.isEdit = this.$route.query.edit;
    }
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      this.roles = await users.getRoles();
    },
    resetForm() {
      this.user = { ...this.userCopy };
      this.toggleEdit();
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
    async fetchUser() {
      this.user = await users.getUser(this.id);
      console.log(this.user);
      this.userCopy = { ...this.user };
    },
    handleError(error) {
      return alert(`${this.$t("Error")}: ${error[0].msg}`);
    },
    async saveUser() {
      if (this.isUserCreate) {
        const { id, detail } = await users.createUser(this.user);
        if (!id) {
          return this.handleError(detail);
        }
        this.id = id;
        this.user = await users.editUser(id, this.user);
        this.$router.replace({ name: "User", params: { id } });
      } else {
        console.log(this.user);
        const data = await users.editUser(this.id, this.user);
        if (!data.id) {
          return this.handleError(data.detail);
        }
        this.user = data;
      }
      this.isUserCreate = false;
      this.isEdit = false;
    },
  },
};
</script>
